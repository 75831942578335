<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-alert v-if="alert" :type="alert.status" dismissible text>
      {{ alert.message }}
    </v-alert>
    <v-card elevation="2" class="pa-5 mt-5">
      <v-container class="py-0">
        <v-row>
          <v-col cols="12" md="12" class="py-0">
            <v-card-title>
              タグ一覧
            </v-card-title>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" class="py-0">
            <v-card-text class="px-0 py-0 d-md-flex">
              <v-card-title class="py-0 mb-auto">
                タグの新規追加
              </v-card-title>
              <v-text-field
                ref="name"
                v-model="name"
                :rules="rules.name"
                :error-messages="errorMessages"
                label="タグ名をご入力ください。"
                required
                class="mt-0 pt-0 px-4"
              ></v-text-field>
              <v-btn
                small
                color="primary"
                @click="submitTag"
                :loading="loading"
                :disabled="loading"
                class=" ml-md-3 d-flex justify-content-center align-item-center"
              >
                追加
              </v-btn>
            </v-card-text>
          </v-col>
        </v-row>
      </v-container>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="Tags"
          :options.sync="options"
          :server-items-length="totalTags"
          :loading="tableLoading"
          :footer-props="{
            'items-per-page-options': [10, 25, 50, 100]
          }"
          :items-per-page="50"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <v-btn
                x-small
                icon
                class="mr-2"
                color="primary"
                :to="{ name: 'TagsEdit', params: { id: item.id } }"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-icon small color="red" @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import api from '@/services/api-methods'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'タグ管理',
          disabled: false,
          to: { name: 'Tags' },
          exact: true
        },
        {
          text: 'タグ一覧',
          disabled: true,
          to: { name: '' },
          exact: true
        }
      ],
      messages: null,
      totalTags: 0,
      Tags: [],
      loading: false,
      tableLoading: true,
      formHasErrors: false,
      errorMessages: '',
      name: null,
      rules: {
        name: [v => !!v || 'タグ名は必須です。']
      },
      options: {
        sortBy: ['id']
      },
      headers: [
        {
          text: 'ID',
          align: 'start',
          // sortable: false,
          value: 'id'
        },
        { text: 'タグ名', value: 'name' },
        // { text: 'Description', value: 'description' },
        { text: 'アクション', value: 'actions', sortable: false }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        this.getTagFromAPI()
      },
      deep: true
    }
  },
  computed: {
    ...mapState(['alert']),
    reqParams() {
      return {
        itemsPerPage: this.options.itemsPerPage,
        page: this.options.page,
        sortBy: this.options.sortBy[0],
        sortDesc: this.options.sortDesc[0]
      }
    },
    form() {
      return {
        name: this.name
      }
    },
    formData() {
      var formData = new FormData()
      formData.append('name', this.name)
      return formData
    }
  },
  mounted() {
    if (this.alert != null) {
      setTimeout(() => {
        this.$store.commit('resetAlert')
      }, 5000)
    }
  },
  methods: {
    getTagFromAPI() {
      this.tableLoading = true
      api.get('tags', this.reqParams).then(res => {
        this.Tags = res.data.data
        this.totalTags = res.data.total
        this.tableLoading = false
      })
    },
    submitTag() {
      this.formHasErrors = false
      Object.keys(this.form).forEach(f => {
        if (!this.form[f]) this.formHasErrors = true
        this.$refs[f].validate(true)
      })
      if (!this.formHasErrors) {
        this.createTag()
      }
    },
    resetForm() {
      this.errorMessages = []
      this.formHasErrors = false
      Object.keys(this.form).forEach(f => {
        this.$refs[f].reset()
      })
    },
    createTag() {
      this.loading = true
      api
        .add('tags', this.formData)
        .then(res => {
          this.status = res.data.status
          this.statusMessage = res.data.message
          this.resetForm()
          this.getTagFromAPI()
          this.alert = true
          this.loading = false
          setTimeout(() => {
            this.alert = false
          }, 5000)
        })
        .catch(e => {
          console.log(e)
        })
    },
    deleteItem(item) {
      this.$swal
        .fire({
          title: 'この操作を実行してもよろしいですか？',
          text: '実行すると元に戻せません。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '削除する',
          cancelButtonText: 'キャンセル'
        })
        .then(result => {
          if (result.isConfirmed) {
            api
              .delete('tags/' + item.id)
              .then(response => {
                if (response.data.status == 'success') {
                  this.$swal.fire('', 'タグを削除しました。', 'success')
                  this.Tags.splice(
                    this.Tags.findIndex(e => e.id == item.id),
                    1
                  )
                }
              })
              .catch(e => {
                this.$swal.fire(
                  'Server error',
                  'Oops! something wrong, please try again later',
                  'error'
                )
              })
          }
        })
    }
  }
}
</script>
